<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">企业会员审核</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item style="width:300px">
						<el-input :maxlength="20" v-model="seachForm.enterpriseName" clearable placeholder="请输入企业会员名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select  v-model="seachForm.operaStatus" clearable placeholder="请选择状态">
							<el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select  v-model="seachForm.provinceId" clearable placeholder="请选择省"  @change="chooseProvince($event)">
							<el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select  v-model="seachForm.cityId" clearable placeholder="请选择市"  @change="chooseCity($event)">
							<el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select  v-model="seachForm.areaId" clearable placeholder="请选择区"  @change="chooseArea($event)">
							<el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="enterpriseName" label="企业会员名称">
				</el-table-column>
				<el-table-column prop="enterpriseNickname" label="昵称" >
				</el-table-column>
				<el-table-column  label="所在地域">
					<template slot-scope="scope">
						<span>{{scope.row.provinceName + scope.row.cityName + scope.row.areaName}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="contacts" label="联系人" >
				</el-table-column>
				<el-table-column prop="phone" label="手机号" >
				</el-table-column>
				<el-table-column prop="createTime" label="提交时间">
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span>{{scope.row.checkStatus == 0 ? '待审核' : scope.row.checkStatus == 1 ? '审核通过' : '审核拒绝'}}</span>
					</template>
				</el-table-column>
                <el-table-column prop="checkTime" label="审核时间" >
				</el-table-column>
				<el-table-column prop="checkBy" label="审核人">
				</el-table-column>
				<el-table-column label="操作" width="200">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" :disabled="scope.row.checkStatus != 0" type="text" size="small">
							修改
						</el-button>
						<el-button @click.native.prevent="auditclick(scope.$index, data)" :disabled="scope.row.checkStatus != 0" type="text" size="small">
							审核
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 修改弹窗 -->
		<el-dialog :visible.sync="addshow" width="800px" :before-close="handleClose" title="修改企业会员">
			<el-form class="form_box" :model="sizeForm" label-position='right'  ref="sizeForm"  size="small">
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>企业会员名称：</p>
							<el-input type="text" :maxlength="30" v-model="sizeForm.enterpriseName" clearable placeholder="请输入企业会员名称"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>昵称：</p>
							<el-input type="text" :maxlength="30" v-model="sizeForm.enterpriseNickname" clearable placeholder="请输入昵称"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>联系人：</p>
							<el-input type="text" :maxlength="30" v-model="sizeForm.contacts" clearable placeholder="请输入联系人"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>手机号：</p>
							<el-input type="text" v-model="sizeForm.phone" :maxlength="11" clearable placeholder="请输入手机号"  oninput="if(value.length>11)value=value.slice(0,11)"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;">所在区域：</p>
							<el-select style="width:160px;" v-model="sizeForm.provinceName" clearable placeholder="请选择省"  @change="chooseProvince2($event)">
								<el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
							</el-select>
							<el-select  style="width:160px;margin-left:20px;"  v-model="sizeForm.cityName" clearable placeholder="请选择市"  @change="chooseCity2($event)">
								<el-option v-for="item in cityList2" :key="item.id" :label="item.name" :value="item.id"> </el-option>
							</el-select>
							<el-select style="width:160px;margin-left:20px;" v-model="sizeForm.areaName" clearable placeholder="请选择区"  @change="chooseArea2($event)">
								<el-option v-for="item in areaList2" :key="item.id" :label="item.name" :value="item.id"> </el-option>
							</el-select>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button type="primary" @click.native.prevent="onUpdata('sizeForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 审核弹窗 -->
		<el-dialog :visible.sync="dialogVisible" width="600px" :before-close="handleClose" title="审核">
			<el-form class="form_box" :model="sizeForm" label-position='right'  ref="sizeForm" label-width="150px" size="small">
                <el-form-item label="企业会员名称：">
                    <span>{{sizeForm.enterpriseName }}</span>
				</el-form-item>
                <el-form-item label="昵称：">
                    <span>{{sizeForm.enterpriseNickname }}</span>
				</el-form-item>
                <el-form-item label="联系人：">
                    <span>{{sizeForm.contacts}}</span>
				</el-form-item>
                <el-form-item label="手机号：">
                    <span>{{sizeForm.phone}}</span>
				</el-form-item>
                <el-form-item label="状态：">
					<el-radio-group v-model="checkStatus">
						<el-radio label="1">审核通过</el-radio>
						<el-radio label="2">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item size="large" style="display:flex;">
                    <el-button @click.native.prevent="handleClose('sizeForm')">取消</el-button>
					<el-button type="primary"  @click.native.prevent="auditSubmit('sizeForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		imageUrl
	} from '@/config/public';
	import {
		enterprisemembercheckList,enterprisemembercheckUpdate,getCity,enterprisememberCheck
	} from "@/api/platform/index";
	export default {
		name: "enterprisemembercheckList",
		data() {
			var blurPhone = async(rule, value, callback) => {  
				const boolean = new RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$').test(value)
				// console.log(boolean)
				if (!boolean) {
					this.$message.warning('请输入正确的手机号')
				}
			}
			return {
				checkStatus:'1',
				seachForm:{
					enterpriseName:'',
					operaStatus:'',
					provinceId:'',
					cityId:'',
					areaId:'',
				},
				resource:'1',
				image_url: imageUrl,
				statusOption: [{label: '正常',value: 0},{label: '冻结',value: 1}],
				headers: {
					Authorization: this.$store.state.user.Token
				},
				dialogVisible: false,
				data: [],
				addshow: false,
				sizeForm: {
					areaId: '',
					areaName:'',
					cityId: '',
					cityName: '',
					contacts: '',
					enterpriseName: '',
					enterpriseNickname: '',
					id: '',
					phone: '',
					provinceId: '',
					provinceName: '',
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				id: 0,
				provinceList:[],
				cityList:[],
				areaList:[],
				provinceList2:[],
				cityList2:[],
				areaList2:[],
			};
		},
		mounted() {
			let that = this;
			that.list();
			that.getProvinceList();
		},
		created() {
		},
		methods: {
			
			//搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//获取列表
			list() {
				let that = this;
				enterprisemembercheckList({
					page: that.page,
					size: that.pageSize,
					areaId: that.seachForm.areaId,
					cityId: that.seachForm.cityId,
					provinceId: that.seachForm.provinceId,
					enterpriseName: that.seachForm.enterpriseName,
					operaStatus: that.seachForm.operaStatus,
				}).then(res => {
					console.log(res)
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},

			// 获取省
			getProvinceList(){
				getCity('/0').then(res => {
					if (res.code == 200) {
						this.provinceList = res.data;
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},

			// 选择省
			chooseProvince(e){
				if(e != null && e.length > 0){
					this.seachForm.provinceId = e;
					this.cityList = [];
					this.seachForm.cityId = '';
					this.seachForm.areaId = '';
					getCity(e).then(res => {
						if (res.code == 200) {
							this.cityList = res.data;
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				}else{
					this.seachForm.cityId = '';
					this.seachForm.areaId = '';
					this.cityList = [];
					this.areaList = [];
				}
			},

			// 选择市
			chooseCity(e){
				if(e != null && e.length > 0){
					this.seachForm.cityId = e;
					this.areaList = [];
					this.seachForm.areaId = '';
					getCity(e).then(res => {
						if (res.code == 200) {
							this.areaList = res.data;
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				}else{
					this.seachForm.areaId = '';
					this.areaList = [];
				}
			},
			
			// 选择区
			chooseArea(e){
				if(e != null && e.length > 0){
					this.seachForm.areaId = e;
				}
			},

			// 选择省
			chooseProvince2(e){
				if(e != null && e.length > 0){
					this.sizeForm.provinceId = e;
					this.cityList2 = [];
					this.sizeForm.cityId = '';
					this.sizeForm.areaId = '';
					this.sizeForm.cityName = '';
					this.sizeForm.areaName = '';
					var index = this.provinceList.findIndex((value)=> value.id == e);
					this.sizeForm.provinceName = this.provinceList[index].name;
					getCity(e).then(res => {
						if (res.code == 200) {
							this.cityList2 = res.data;
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				}else{
					this.sizeForm.cityId = '';
					this.sizeForm.areaId = '';
					this.sizeForm.cityName = '';
					this.sizeForm.areaName = '';
					this.cityList2 = [];
					this.areaList2 = [];
				}
			},

			// 选择市
			chooseCity2(e){
				if(e != null && e.length > 0){
					this.sizeForm.cityId = e;
					this.areaList2 = [];
					this.sizeForm.areaId = '';
					this.sizeForm.areaName = '';
					var index = this.cityList2.findIndex((value)=> value.id == e);
					this.sizeForm.cityName = this.cityList2[index].name;

					getCity(e).then(res => {
						if (res.code == 200) {
							this.areaList2 = res.data;
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				}else{
					this.sizeForm.areaId = '';
					this.sizeForm.areaName = '';
					this.areaList2 = [];
				}
			},
			
			// 选择区
			chooseArea2(e){
				if(e != null && e.length > 0){
					this.sizeForm.areaId = e;
					var index = this.areaList2.findIndex((value)=> value.id == e);
					this.sizeForm.areaName = this.areaList2[index].name;
				}
			},
			
			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

			//编辑提交
			onUpdata(formName){
				let that = this;
				if(that.addshow){
					that.$refs[formName].validate((valid) => {
						if (valid) {
							if(that.$refs[formName].model.enterpriseName != ''){
								if(that.$refs[formName].model.enterpriseNickname != ''){
									if(that.$refs[formName].model.contacts != ''){
										if(that.$refs[formName].model.phone.length == 11){
											if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.$refs[formName].model.phone))){
												that.$message.warning('手机号不合法');
												return;
											}
											enterprisemembercheckUpdate({
												areaId: that.$refs[formName].model.areaId,
												areaName:that.$refs[formName].model.areaName,
												cityId: that.$refs[formName].model.cityId,
												cityName: that.$refs[formName].model.cityName,
												provinceId: that.$refs[formName].model.provinceId,
												provinceName: that.$refs[formName].model.provinceName,
												contacts: that.$refs[formName].model.contacts,
												enterpriseName: that.$refs[formName].model.enterpriseName,
												enterpriseNickname: that.$refs[formName].model.enterpriseNickname,
												phone: that.$refs[formName].model.phone,
												id: that.$refs[formName].model.id,
											}).then(res=>{
												if (res.code == 200) {
													that.$message.success('编辑成功');
													that.sizeForm = {
														areaId: '',
														areaName:'',
														cityId: '',
														cityName: '',
														provinceId: '',
														provinceName: '',
														contacts: '',
														enterpriseName:'',
														enterpriseNickname: '',
														phone: '',
														id:''
													}
													that.addshow = false
													that.page = 1
													that.list();
												} else {
													that.$message.error(res.msg);
												}
											}).catch(err => {
												that.$message.error(err.msg);
											})
										}else{
											that.$message.warning('手机号格式不正确');
										}
									}else{
										that.$message.warning('联系人不能为空');
									}
								}else{
									that.$message.warning('企业会员昵称不能为空');
								}
							}else{
								that.$message.warning('企业会员名称不能为空');
							}
						} else {
							return false;
						}
					});
				}
			},

			// 审核提交
			auditSubmit(formName){
				if(this.dialogVisible){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							console.log(this.$refs[formName].model);
							enterprisememberCheck({
								checkStatus: this.checkStatus,
								id: this.$refs[formName].model.id,
							}).then(res=>{
								if (res.code == 200) {
									this.$message.success('提交成功');
									this.sizeForm = {
										areaId: '',
										areaName:'',
										cityId: '',
										cityName: '',
										provinceId: '',
										provinceName: '',
										contacts: '',
										enterpriseName:'',
										enterpriseNickname: '',
										phone: '',
										id:''
									}
									this.dialogVisible = false;
									this.checkStatus = '1';
									this.page = 1;
									this.list();
								} else {
									this.$message.error(res.msg);
								}
							}).catch(err => {
								this.$message.error(err.msg);
							})
						} else {
							return false;
						}
					});
				}
			},


			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					areaId: '',
					areaName:'',
					cityId: '',
					cityName: '',
					provinceId: '',
					provinceName: '',
					contacts: '',
					enterpriseName:'',
					enterpriseNickname: '',
					phone: '',
					id:''
				}
				this.addshow = false;
				this.dialogVisible = false;
				this.checkStatus = '1';
			},

			//编辑按钮点击
			updataclick(i,tab){
				console.log(tab[i]);
				this.sizeForm = {
					areaId: tab[i].areaId,
					areaName:tab[i].areaName,
					cityId: tab[i].cityId,
					cityName: tab[i].cityName,
					contacts: tab[i].contacts,
					enterpriseName: tab[i].enterpriseName,
					enterpriseNickname: tab[i].enterpriseNickname,
					id: tab[i].id,
					phone: tab[i].phone,
					provinceId: tab[i].provinceId,
					provinceName: tab[i].provinceName,
				}
				this.getProvinceList();
				this.id = tab[i].id
				this.addshow = true
			},

			// 审核按钮点击
			auditclick(i,tab){
				console.log(tab[i]);
				this.sizeForm = {
					areaId: tab[i].areaId,
					areaName:tab[i].areaName,
					cityId: tab[i].cityId,
					cityName: tab[i].cityName,
					contacts: tab[i].contacts,
					enterpriseName: tab[i].enterpriseName,
					enterpriseNickname: tab[i].enterpriseNickname,
					id: tab[i].id,
					phone: tab[i].phone,
					provinceId: tab[i].provinceId,
					provinceName: tab[i].provinceName,
				}
				this.getProvinceList();
				this.id = tab[i].id
				this.dialogVisible = true
			}
			
		}
	}
</script>

<style scoped lang="scss">
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}
	.search{display:flex;align-items:center;}
	.search .el-form-item{margin:0 20px 0 0 !important;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
</style>
